export default {
    computed: {
        headers() {
            return [
                {
                    value: 'id',
                    text: this.$t('labels.id'),
                    type: 'string',
                },
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                    type: 'string',
                },
                {
                    value: 'token',
                    text: this.$t('labels.token'),
                    type: 'string',
                    width: '300',
                },
            ]
        },
    },
}
